.line-btn {
  a,
  button,
  input {
    border: 0;
    background: transparent;
    text-transform: uppercase;
    color: #212529;
    display: inline-block;
    position: relative;
    padding-top: 138px;
    transition: 0.8s;

    &:hover {
      &::before {
        height: 0;
        visibility: hidden;
      }

      &::after {
        width: 90%;
      }
    }

    &::before {
      position: absolute;
      content: "";
      width: 1px;
      background: #212529;
      height: 80px;
      bottom: 40px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: 0.8s;
    }

    &::after {
      position: absolute;
      content: "";
      width: 0;
      background: #212529;
      height: 1px;
      left: 0;
      bottom: -3px;
      transition: 0.7s;
    }
  }
}
