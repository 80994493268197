footer {
  padding: 80px 0 40px;
  position: relative;
  background: #e58c23;
  color: #fff;

  .reach_out {
    display: flex;
    width: 180px;
    height: 48px;
    border: 1px solid #fff;
    border-radius: 50px;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;

    a {
      color: #e58c23;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      width: 60px;
      height: 60px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    p {
      font-size: 14px;
      margin-bottom: 0;
      line-height: 1;
      margin-left: 15px;
    }
  }

  .footer_half {
    display: flex;
    justify-content: space-between;
  }

  .connect {
    h5 {
      font-size: 16px;
      opacity: 0.5;
      font-family: "GothamBook";
      text-transform: uppercase;
      color: #fff;
    }

    a,
    p {
      display: block;
      color: #fff;
      text-decoration: none;
      font-size: 18px;
      margin: 5px 0;
      font-family: "GothamPro";
    }
  }

  .socail {
    text-align: right;
    padding-top: 50px;

    a {
      width: 50px;
      height: 50px;
      border: 1px solid #fff;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-left: 20px;
      text-decoration: none;
    }
  }

  .footer_bottom {
    margin-top: 60px;
    border-top: 1px solid #e7ad68;
    padding-top: 30px;

    p {
      font-size: 14px;
      line-height: 0;
      margin-bottom: 0;
      color: #fff;

      a {
        color: #fff;
        text-decoration: none;
        font-family: "GothamSSm-XLight";
      }

      &.align-right {
        text-align: right;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .footer_logo {
    h4 {
      font-size: 24px;
    }
  }
}

@media screen and (max-width: 500px) {
  .footer_logo {
    h4 {
      font-size: 24px;
    }

    margin-bottom: 70px;
  }

  footer {
    .footer_half {
      display: block;

      .half {
        margin-bottom: 50px;
      }
    }

    .socail {
      text-align: center;
    }

    .footer_bottom {
      p {
        line-height: 1;
        text-align: center;
      }

      p.align-right {
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}
