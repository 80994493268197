.about_half {
  .image {
    img {
      width: 100%;
      height: 546px;
      object-fit: cover;
    }
  }

  .text {
    &.padding_left {
      padding-left: 50px;
      padding-right: 0;
    }

    padding-right: 50px;
  }
}

@media screen and (max-width: 800px) {
  .intro_head article {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .intro_head article {
    display: block;
  }

  .about_half {
    .text {
      margin: 45px 0;
    }

    .text.padding_left {
      padding-left: 0;
    }
  }
}
