.preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background: #fff;
  top: 0;
  margin: 0;
  padding: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .img {
    img {
      width: 250px;
      animation: beat 1.5s linear;
    }
  }
}

@keyframes beat {
  to {
    transform: translateY(-200px);
    opacity: 0;
  }
}
