.av_section {
  .sec-head {
    margin-bottom: 40px;
  }

  .av_slides {
    .slides {
      margin: 10px 0;
      padding: 25px 0;
      box-shadow: 0 0 1.875px rgb(0 0 0 / 10%);
      text-align: center;

      &:hover {
        cursor: pointer;
        img {
          filter: grayscale(0);
        }
      }
    }

    img {
      width: 60%;
      margin: auto;
      // filter: grayscale(1);
    }
  }
}

.slide_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .slides {
    margin: 10px 20px;
    text-align: center;
    padding: 5px;

    img {
      width: 150px;
      border: 1px solid #cdcdcd;
      padding: 5px;
      margin-bottom: 10px;
    }
  }
}
