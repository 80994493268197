.product_list {
  margin-bottom: 50px;
}

.product_detail {
  img {
    width: 100%;
  }

  .text {
    padding-left: 40px;

    h4 {
      font-size: 35px;
    }

    h6 {
      margin: 10px 0;
      font-family: "GothamBook";
    }
  }
}

.see_all {
  text-align: center;
  margin-top: 30px;
}

@media screen and (max-width: 800px) {
  .product_detail .text {
    padding-left: 0;
    margin-top: 40px;
  }
}
