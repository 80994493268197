.banner_inner {
  height: 60vh;
  background-position: center !important;
  background-size: cover !important;
  margin-bottom: 40px;
  position: relative;

  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(
      0deg,
      hsla(0, 0%, 100%, 0) 55%,
      rgba(45, 45, 45, 0.661502)
    );
  }

  .text {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #fff;
    width: 570px;
    max-width: 100%;

    h4 {
      color: #fff;

      text-transform: uppercase;
      text-shadow: 2px 4px 3px rgb(0 0 0 / 30%);
    }
  }
}
