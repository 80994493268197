header {
  position: absolute;

  z-index: 9;
  width: 100%;
  background: transparent;
  // display: none;

  &.active {
    transition: 0.8s;
    background: rgb(253, 253, 253);
    position: fixed;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 19%);
    display: none;

    .logo {
      img {
        width: 90px;
      }
    }

    .navs {
      ul {
        li {
          a {
            color: #212529;
          }
        }
      }
    }
  }

  .logo {
    img {
      width: 110px;
    }
  }

  .navs {
    ul {
      display: flex;
      justify-content: end;

      li {
        padding: 0 30px;

        &:last-child {
          padding-right: 0;
        }

        a {
          color: #fff;
          text-transform: uppercase;
        }
      }
    }
  }
}

.nav_click {
  display: none;
  position: absolute;
  z-index: 3;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);

  &.active {
    span {
      &:last-child {
        transform: rotate(45deg) translate(-7px, -7px);
      }
      &:first-child {
        transform: rotate(-45deg);
      }
    }
  }

  span {
    width: 40px;
    height: 1px;
    background-color: #212529;
    display: block;
    transition: 0.5s;
    margin: 10px 0;
  }
}

@media screen and (max-width: 800px) {
  header {
    background: #fff;
    position: relative;

    .nav_click {
      display: block;
    }

    .navs {
      position: absolute;
      width: 100%;
      height: 100vh;
      background-color: #fff;
      left: 0;
      top: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transform: translateY(-100%);
      visibility: hidden;
      transition: 0.5s;

      &.show {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      ul {
        display: block;
        padding-left: 0;

        li {
          margin: 20px 0;
          padding: 0;
          text-align: center;
          a {
            color: #000000d9;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  header {
    padding: 10px 0;

    .logo img {
      width: 90px;
    }
  }
}
