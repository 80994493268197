.co_wrap {
  h5 {
    font-size: 16px;
    color: #e58c23;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  a,
  p {
    color: #212529;
    font-family: "GothamBook";
    line-height: 1.5;
    display: block;
    margin-bottom: 5px;
    max-width: 230px;
  }
}

.contact_map {
  a {
    text-transform: uppercase;
    color: #212529;
    display: block;

    i {
      margin-left: 4px;
    }
  }
}

form#contact_form {
  max-width: 750px;
  margin: auto;
  margin-top: 50px;

  .ant-row.ant-form-item-row {
    display: block;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number {
    width: 100%;
  }

  input {
    padding: 10px;
    height: auto;
  }

  textarea {
    padding: 10px;
    height: 150px;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: inherit;
    border-color: inherit;
    background: inherit;
  }

  .line-btn {
    text-align: center;

    button {
      height: auto;

      &::before {
        content: "";
        display: block;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .co_wrap {
    margin: 15px 0;
  }
}
