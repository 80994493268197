.sperate {
  padding: 50px 0;

  .sep-sing {
    text-align: center;
    margin: 15px 0;

    img {
      width: 130px;
    }

    h5 {
      font-family: "GothamBook";
      font-size: 20px;
      text-transform: uppercase;
      max-width: 190px;
      margin: 10px auto;

      span {
        display: block;
      }
    }
  }
}
