* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "GothamPro";
}

.container {
  max-width: 1140px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Gotham-Medium";
}

h1 {
  font-size: 100px;
  font-family: "GothamPro-Bold";
}

h4 {
  font-size: 38px;
  font-family: "Gotham-Medium";
}

h5 {
  font-size: 24px;
  font-family: "GothamBook";
}

p {
  font-size: 16px;
  font-family: "GothamSSm-XLight";
  line-height: 1.6;
}

a {
  text-decoration: none;
}

a.mail-underline {
  text-decoration: underline;
}

a:hover {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.nav-link:focus {
  color: #e58c23;
}

.no-padding {
  padding: 0;
}

section {
  margin: 30px 0;
  padding: 40px 0;
}

ul {
  list-style: none;
}

.sec-head {
  text-align: center;

  h5 {
    text-transform: uppercase;
  }
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.intro_head {
  text-align: center;

  h6 {
    font-size: 16px;
    margin-bottom: 20px;
    color: #e58c23;
    text-transform: uppercase;
  }

  h4 {
    width: 60%;
    margin: auto auto 20px;
    font-size: 32px;
  }

  article {
    display: flex;
    margin-top: 40px;

    p {
      flex: 1 1;
      padding: 0 80px;
    }
  }
}

@media screen and (max-width: 800px) {
  .intro_head {
    h4 {
      width: 80%;
      margin: auto;
    }

    article {
      display: block;

      P {
        padding: 0;
        flex: none;
      }
    }
  }
}
