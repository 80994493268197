.banner {
  position: relative;
  margin: 0;
  padding: 0;

  video {
    width: 100%;
  }

  .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;

    h1 {
      text-transform: uppercase;
      color: #fff;
    }
  }
}

.intro {
  .intro-text {
    text-align: center;

    h4 {
      margin-bottom: 20px;
    }
  }

  .intro-img {
    img {
      width: 48vw;
    }
  }
}

.video {
  .row {
    align-items: flex-end;
  }

  .vd-bg {
    width: 48vw;
    height: 70vh;
    background-position: center !important;
    background-size: cover !important;
    position: relative;

    video.vd-mdl {
      width: 100%;
      height: 100%;
    }

    .play-btn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      cursor: pointer;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid #e58c23;
        animation: ripple 2s linear infinite;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid #e58c23;
        animation: ripple 2s linear 1s infinite;
      }

      img {
        width: 70px;
        height: 70px;
        background: #e58c23;
        padding: 15px 10px 15px 15px;
        border-radius: 50%;
      }
    }
  }

  .vd-text {
    width: 60%;
    padding-left: 50px;

    h4 {
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.products {
  margin-top: 80px;

  .prd-slide {
    padding: 20px 0;
    margin-top: 30px;
    width: 100%;
    overflow: hidden;

    .prdts-more {
      text-align: center;
    }
  }
}

.sp-wrap {
  padding: 0 5px;
  margin: 20px 0;
  position: relative;

  img {
    width: 100%;
    transition: 0.5s;
    height: 454px;
    object-fit: cover;
  }

  h6 {
    font-size: 18px;
    position: absolute;
    bottom: 20px;
    left: 40px;
    font-family: "GothamBook";
    color: #333;
    transition: 0.3s;
    text-decoration: none;

    &::after {
      position: absolute;
      content: "";
      width: 0;
      background: #e58c23;
      height: 1px;
      left: 110%;
      bottom: 8px;
      transition: 0.7s;
    }
  }

  a {
    &:hover {
      h6 {
        color: #e58c23 !important;

        &::after {
          width: 50px;
        }
      }
    }
  }
}

.testimonials {
  .sec-head {
    margin-bottom: 60px;
  }

  .container {
    max-width: 1400px;
  }
}

.clientSlides {
  padding-top: 40px;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    height: 520px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;

    &.swiper-slide-active {
      background: #e58c23;
      height: 670px;

      .cl-wrap {
        color: #fff;

        .desc {
          h5 {
            color: #fff;
          }
        }

        img {
          transform: scale(1.6);
          margin-bottom: 40px;
        }
      }
    }
  }

  .cl-wrap {
    padding: 50px 30px;
    text-align: center;

    img {
      border-radius: 50%;
      width: 175px;
      height: 175px;
      object-fit: cover;
    }

    .desc {
      padding-top: 40px;

      h5 {
        font-size: 18px;
        text-transform: uppercase;
        margin-top: 25px;
        margin-bottom: 0;
      }

      span {
        font-weight: 100;

        font-size: 15px;
      }
    }
  }
}

// .home_diff {
//   background: #dcecec;
// }

@keyframes ripple {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@media screen and (max-width: 800px) {
  .banner .banner-text h1 {
    font-size: 32px;
  }

  .video .vd-text {
    width: 80%;
  }
}

@media screen and (max-width: 500px) {
  .banner video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .banner .banner-text h1 {
    font-size: 32px;
  }

  .intro .intro-img img {
    width: 100%;
    margin-top: 50px;
  }

  .video .vd-bg {
    width: 100%;
    margin-bottom: 50px;
  }

  .video .vd-text {
    width: 100%;
    padding-left: 0;
  }

  .clientSlides .swiper-slide.swiper-slide-active .cl-wrap img {
    transform: scale(1);
  }
}
