.difference {
  background-color: #dcecec;
  padding: 0;
  margin-top: 80px;

  .wrapper {
    position: relative;
    min-height: 760px;
    overflow: hidden;

    img {
      max-width: 850px;
    }

    .lead,
    .jar,
    .bg_img {
      position: absolute;
      bottom: 0;
      max-width: 750px;
      left: 50%;
      transform: translateX(-50%);
    }

    .ingredients {
      width: 640px;
      height: 100%;
      position: absolute;
      // z-index: 1;
      top: 110px;
      left: 50%;
      transform: translateX(-50%);

      img {
        position: absolute;
        width: 750px;
        height: 750px;
        object-fit: contain;
        z-index: 3;
      }
    }

    .jar {
      z-index: 1;

      img {
        position: relative;
        z-index: 0 !important;
      }

      .back {
        position: absolute;
      }
    }

    .lead {
      z-index: 3;
      transform: translateY(-300);
    }

    // .bg_img {
    //   img {
    //     width: 980px;
    //     max-width: 980px;
    //   }
    // }
  }
}
